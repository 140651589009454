import React from 'react'
import Layout from '../layouts'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import buyingBG from '../images/buying-bg.jpg'
// import CommonHero from '../components/commonhero/commonhero.js'

class BuyingProcessPage extends React.Component {
  render() {
    return (
      <Layout
        head={{
          title: 'The Buying Process',
        }}
      >
        <div className="page-header">
          <div
            className="page-banner buying"
            style={{
              width: `100%`,
              height: `100%`,
              background: `url(${buyingBG})`,
              top: '12%',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          />
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-6">
                <div className="hero-content align-self-center">
                  <h1 className="text-white align-self-center">
                    The Buying Process
                  </h1>
                  <p className="text-white">
                    Give Brian a call at{' '}
                    <a href="tel:7194599955" className="text-white">
                      (719) 459-9955
                    </a>{' '}
                    to set-up on your custom search portal plan today!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <main className="bg-white">
          <div className="container py-5">
            <div class="intro text-center my-5">
              <h2 className="text-black">Are you a homebuyer?</h2>
              <h6 class="subtitle text-black">
                Here is the process to buy a home:
              </h6>
            </div>
            <div className="buying-item bg-light-blue mb-5">
              <div className="row align-items-start">
                <div className="col-md-3 col-xl-2 mb-4 mb-md-0">
                  <h5 className="buying-number">1</h5>
                </div>
                <div className="col-md-9 col-xl-10">
                  <div className="buying-info">
                    <h4 className="mb-3">Prequalify</h4>
                    <div className="info-list">
                      <ul>
                        <li>
                          Meet in-person or via Zoom to discuss your plans and
                          home search criteria.
                        </li>
                        <li>Get pre-qualified with a lender.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="buying-item mb-5">
              <div className="row align-items-start">
                <div className="col-md-3 col-xl-2 mb-4 mb-md-0">
                  <h5 className="buying-number">2</h5>
                </div>
                <div className="col-md-9 col-xl-10">
                  <div className="buying-info">
                    <h4 className="mb-3">Home Search</h4>
                    <div className="info-list">
                      <ul>
                        <li>
                          View homes in your customized search portal and choose
                          which homes you would like to tour.
                        </li>
                        <li>
                          Meet in-person or via video to tour homes and pick out
                          the right home for you!
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="buying-item bg-light-blue mb-5">
              <div className="row align-items-start">
                <div className="col-md-3 col-xl-2 mb-4 mb-md-0">
                  <h5 className="buying-number">3</h5>
                </div>
                <div className="col-md-9 col-xl-10">
                  <div className="buying-info">
                    <h4 className="mb-3">Make an Offer</h4>
                    <div className="info-list">
                      <ul>
                        <li>
                          Once your offer is accepted and you are under
                          contract, we provide you with information to deposit
                          your Earnest Money Deposit.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="buying-item mb-5">
              <div className="row align-items-start">
                <div className="col-md-3 col-xl-2 mb-4 mb-md-0">
                  <h5 className="buying-number">4</h5>
                </div>
                <div className="col-md-9 col-xl-10">
                  <div className="buying-info">
                    <h4 className="mb-3">Home Inspection</h4>
                    <div className="info-list">
                      <ul>
                        <li>
                          You will call a licensed home inspector to schedule
                          the home inspection within contract deadlines (we can
                          provide you with a list of recommended inspectors).
                        </li>
                        <li>
                          We will send the contract to your lender and your
                          lender will be in touch with all items they require
                          from you to close your loan. Lender will order the
                          appraisal.
                        </li>
                        <li>
                          You will shop around for insurance rates and provide
                          insurance information to your lender.
                        </li>
                        <li>
                          We will obtain property disclosures and send them to
                          you for review and signatures, and answer any
                          questions found in the disclosures.
                        </li>
                        <li>
                          Title is ordered to verify there are no liens against
                          the property and it can be deeded to you with clear
                          title. You will review any covenants on the property
                          to make sure you can abide by any covenants set with
                          the area.
                        </li>
                        <li>
                          If the property has a HOA, all HOA documents will be
                          provided to you to review to ensure you will have no
                          issues with the HOA rules and regulations.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="buying-item bg-light-blue mb-5">
              <div className="row align-items-start">
                <div className="col-md-3 col-xl-2 mb-4 mb-md-0">
                  <h5 className="buying-number">5</h5>
                </div>
                <div className="col-md-9 col-xl-10">
                  <div className="buying-info">
                    <h4 className="mb-3">Resolution</h4>
                    <div className="info-list">
                      <ul>
                        <li>
                          Once the home inspection is complete, we will review
                          the report with you and discuss the items you would
                          like to ask the seller to complete on an Inspection
                          Objection.
                        </li>
                        <li>
                          Once you and the seller have agreed upon inspection
                          items, you both will sign an Inspection Resolution.
                        </li>
                        <li>
                          Closing and final walk through will be scheduled.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>{' '}
            <div className="buying-item mb-5">
              <div className="row align-items-start">
                <div className="col-md-3 col-xl-2 mb-4 mb-md-0">
                  <h5 className="buying-number">6</h5>
                </div>
                <div className="col-md-9 col-xl-10">
                  <div className="buying-info">
                    <h4 className="mb-3">Closing</h4>
                    <div className="info-list">
                      <ul>
                        <li>
                          Lender will complete the loan and provide you with a
                          Closing Disclosure of your closing cost breakdown.
                        </li>
                        <li>
                          We will obtain paid receipts for the repairs agreed
                          upon in the Inspection Resolution.
                        </li>
                        <li>
                          You will complete a final walk through with Brian,
                          then head to closing.
                        </li>
                        <li>
                          You will sign all loan and closing documents, then be
                          given keys to your new home!
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export default BuyingProcessPage
